require( 'imagemapster' );
import 'magnific-popup';

jQuery( document ).ready( function ( $ ) {
	$( 'img[usemap]' ).mapster( {
		fill: false,
		strokeColor: 'ffffff',
		strokeWidth: 2,
		stroke: true,
		singleSelect: true,
		onConfigured: function ( success ) {
			$( '.mapster_el' ).attr( 'alt', 'Studio' );
		}
	} );

	$( 'map area' ).on( 'click', function ( e ) {
		e.preventDefault();
		const href = $( this ).attr( 'href' );

		$.magnificPopup.open( {
			items: {
				src: href,
				type: 'inline'
			}
		} );
	} );

	$( window ).on( 'resize', function () {
		$( 'img[usemap]' ).mapster( {
			fill: false,
			strokeColor: 'ffffff',
			strokeWidth: 2,
			stroke: true,
			singleSelect: true,
			onConfigured: function ( success ) {
				$( '.mapster_el' ).attr( 'alt', 'Studio' );
			}
		} );
	} );

	/*$.magnificPopup.open( {
		items: {
			src: '#modal-intro',
			type: 'inline'
		}
	} );*/

	$( '.overlay .btns .btn-email' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( '.btns li:nth-child(-n+3)' ).hide();
		$( '#form_group' ).show();
		$( '#email_form' ).show();
	} );

	$( '#email_form' ).on( 'submit', function ( e ) {
		e.preventDefault();
		var data = $( this ).serialize();
		$.ajax( {
			type: 'POST',
			url: 'https://subs.sonymusicfans.com/submit',
			dataType: 'json',
			data: data,
			xhrFields: {
				withCredentials: false
			},
			success: function ( data ) {
				closeIntro();
			},
			error: function ( err ) {
				// Do things when submission has an error.
				alert( 'An error has occurred!' );
			}
		} );
	} );

	let SMEPreSave = {
		state: null,
		spotify_user: null,
		url_vars: [],
		getURLVars: function ( callback, form_id ) {
			let hash;
			let hashes = window.location.href.slice( window.location.href.indexOf( '?' ) + 1 ).split( '&' );
			for ( let i = 0; i < hashes.length; i ++ ) {
				hash = hashes[i].split( '=' );
				this.url_vars.push( hash[0] );
				this.url_vars[hash[0]] = hash[1];
			}

			this.state = 'state' in this.url_vars ? this.url_vars['state'] : null;
			this.ae_member_id = 'ae_member_id' in this.url_vars ? this.url_vars['ae_member_id'] : null;
			this.spotify_user = 'spotify_user' in this.url_vars ? this.url_vars['spotify_user'] : null;
			this.deezer_user = 'deezer_user' in this.url_vars ? this.url_vars['deezer_user'] : null;
			this.message = 'message' in this.url_vars ? this.url_vars['message'] : null;

			if ( typeof callback === 'function' ) {
				callback( this.state, this.ae_member_id, this.spotify_user, this.deezer_user, this.message );
			}
		}
	};


	/* String state
	 *  The status of the pre-save.
	 *  Int ae_member_id
	 *  The Appreciation Engine member ID. This can be passed to the forms processor.
	 *  String spotify_user
	 *  An encrypted string identifying the Spotify user. Can be passed to the pre-save forms processor.
	 *  String deezery_user
	 *  An encrypted string identifying the Deezer user. Can be passed to the pre-save forms processor.
	 */

	SMEPreSave.getURLVars( function ( state, ae_member_id, spotify_user, deezer_user, message ) {
		if ( state === 'success' || state === 'thank-you' ) {
			closeIntro();
			$( '#apple_embed' ).hide();
		}
	} );

	// Wrap your logic a button click event
	$( '.overlay .btns .btn-apple' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( '.btns li:nth-child(-n+3)' ).hide();
		$( '#form_group' ).show();
		$( '#apple_music_form' ).show();
		$( '#spotify_embed' ).hide();

		let AmkPlaylists = [];
		let AmkAlbums = ['840431884'];
		// Create a new SMEAppleMusic object with your parameters
		let sm = new SMEAppleMusic( {
			ae: {
				ae: '6f1f5af4a7bf0cf12b35542b7483e2a95e76897ed3628432f625dff3b42e00c4',
				brand_id: '3447914',
				segment_id: '1515337',
				activities: '{"actions":{"presave":91293},"mailing_list_optins":{"a0S61000002yg58EAA":91294}}'
			},
			am: {
				dev_token: '',
				save_mode: 'library',
				custom_playlist_name: '',
				resources: {
					playlists: AmkPlaylists,
					albums: AmkAlbums
				}
			},
			sf: {
				form: '186897',
				default_mailing_list: 'a0S61000002yg58EAA'
			},
			smf: {
				campaign_id: '186897',
				campaign_key: 'c02c39898644f481a4d5e6c9ac66de2e'
			}
		} );
		document.getElementById( 'apple_music_form' ).addEventListener( 'submit', function ( e ) {
			e.preventDefault();
			// Grab the email address value
			const email = document.getElementById( 'apple_music_email_address' ).value;
			// Pass the email address and opt in status
			// This can be true or false depending on if the user is forced into the list, or chooses via a checkbox or similar.
			sm.doActions( email, true ).then( function ( res ) {
				closeIntro();
			} ).catch( function ( err ) {
				console.log( 'An error has occurred!' );
			} );
		} );
	} );

	function closeIntro() {
		$( '.overlay' ).fadeOut( '400', function () {
			$( 'body' ).removeClass( 'has-gate' );
			setTimeout( function () {
				$.magnificPopup.open( {
					items: {
						src: '#modal-intro',
						type: 'inline'
					}
				} );
			}, 250 );
		} );
	}
} );
